define(['app'], function() {

  

  var properties = {
    apiUrl: 'https://www.youtube.com/player_api',
    cssClasses: {
      trigger: 'js-video-watermark-banner',
      videoItem: 'js-video-watermark-banner__item',
      loaded: 'video-watermark-banner__container--loaded',
      loading: 'video-watermark-banner__container--loading'
    },
    attributes: {
      startTime: 'data-start',
      endTime: 'data-end',
      loop: 'data-loop'
    }
  };

  var youTubeVideoWatermark = function($containerElement) {

    this.$elements = {
      container: $containerElement,
      video: $containerElement.querySelectorAll('.' + properties.cssClasses.videoItem)[0]
    };

    this.times = {
      start: this.$elements.video.getAttribute(properties.attributes.startTime),
      end: this.$elements.video.getAttribute(properties.attributes.endTime)
    };
    this.loop = this.$elements.video.getAttribute(properties.attributes.loop);

    this.getPlayer();
  };

  youTubeVideoWatermark.prototype = {
    mute: function() {
      this.video.mute();
    },
    start: function() {
      this.video.seekTo(this.times.start);
      this.listen();
    },
    stop: function() {
      this.video.pause();
    },
    hasEnded: function() {
      return this.video.getCurrentTime() >= this.times.end;
    },
    listen: function() {
      clearInterval(this.listenforEnd);

      var action = function() {
        if (this.hasEnded()) {
          this.loop ? this.start() : this.stop();
        }
      }.bind(this);

      this.listenforEnd = setInterval(action, 100);
    },
    show: function() {
      this.$elements.container.className = this.$elements.container.className.replace(properties.cssClasses.loading, properties.cssClasses.loaded);
    },
    getPlayer: function() {
      var onPlayerReady = function() {
        this.mute();
        this.show();
        this.start();
      }.bind(this);

      var ytPlayer = function(playerId) {
        var playerObject = {
          playerVars: {
            autoplay: 1,
            controls: 0,
            autohide: 1,
            wmode: 'opaque',
            rel: 0
          },
          videoId: playerId,
          events: {
            'onReady': onPlayerReady
          }
        };
        return new window.YT.Player(playerId, playerObject);
      };
      this.video = ytPlayer(this.$elements.video.id);
    }
  };

  (function() {
    var $videoWaterMarkContainer;
    var loadApi;
    var shouldLoadApi;
    var setContainers;
    var removeLoading;
    var render;
    var listenForApiReady;
    var isApiReadyCheckInterval;

    render = function() {
      clearInterval(isApiReadyCheckInterval);
      new youTubeVideoWatermark($videoWaterMarkContainer[0]);
    };

    /* Youtube Looks for onYouTubePlayerAPIReady on global -
    not wanting to take over that function, we can listen for YT to be defined instead */
    listenForApiReady = function() {
      var youtubeConstructorReady = function() {
        return typeof YT === 'object' && typeof window.YT.Player === 'function';
      };

      isApiReadyCheckInterval = setInterval(
        function() {
          if (youtubeConstructorReady()) {
            render();
          }
        },
        300);
    };

    removeLoading = function() {
      $videoWaterMarkContainer[0].className = $videoWaterMarkContainer[0].className.replace(properties.cssClasses.loading, properties.cssClasses.loaded);
    };

    loadApi = function() {
      var youTubejs = document.createElement('script');
      youTubejs.src = properties.apiUrl;
      youTubejs.async = true;
      document.getElementsByTagName('head')[0].appendChild(youTubejs);
      listenForApiReady();
    };

    shouldLoadApi = function() {
      return $videoWaterMarkContainer && $videoWaterMarkContainer.length > 0;
    };

    setContainers = function() {
      $videoWaterMarkContainer = document.querySelectorAll('.' + properties.cssClasses.trigger);
    };

    (function() {
      setContainers();
      if (shouldLoadApi()) {
        loadApi();
      } else if ($videoWaterMarkContainer.length) {
        removeLoading();
      }
    })();

  })();

});
